/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-03-28",
    versionChannel: "nightly",
    buildDate: "2025-03-28T00:06:35.127Z",
    commitHash: "286c13d76ab4688f1d857cadb8ed751309319a69",
};
