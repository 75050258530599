/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-10-03",
    versionChannel: "nightly",
    buildDate: "2024-10-03T00:06:04.800Z",
    commitHash: "b5b5e76e4ac389bb02269cbd6066ccd6b959a323",
};
